import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#F9F6E6',
        color: 'black',
        py: 4,
        px: 2,
        position: 'relative',
        width: '100%',
        margin: 0,
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Left Section */}
        <Grid item xs={12} sm={6}>
          {/* Footer Heading using Dancing Script */}
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontSize: { xs: '1.2rem', sm: '1.5rem' },
              fontFamily: '"Dancing Script", cursive', // Apply Dancing Script font here
            }}
          >
            Homelike Homestays
          </Typography>
          {/* Footer Text using Mate SC */}
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: '0.7rem', sm: '0.9rem' },
              fontFamily: '"Mate SC", serif', // Apply Mate SC font here
            }}
          >
            Copyright © 2024 All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
