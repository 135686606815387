import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import "./adminPannel.css";
import ImageFetcher from "../ImageFetcher";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import useSessionCheck from "./UserSessionCheck";
// import { useNavigate } from "react-router-dom";

const AdminPanel = () => {
  // useSessionCheck();
  const [aboutUsText, setAboutUsText] = useState({});
  // //console.log("🚀 ~ AdminPanel ~ aboutUsText:", aboutUsText);
  const [servicesData, setServicesData] = useState({});
  // //console.log("🚀 ~ AdminPanel ~ servicesData:", servicesData);
  const [carouselImages, setCarouselImages] = useState({});
  // //console.log("🚀 ~ AdminPanel ~ carouselImages:", carouselImages);
  const [contactData, setContactData] = useState({});
  // //console.log("🚀 ~ AdminPanel ~ contactData:", contactData);
  const [metadataData, setMeatdataData] = useState({});
  console.log("🚀 ~ AdminPanel ~ metadataData:", metadataData);
  const [heroData, setHeroData] = useState({});
  // //console.log("🚀 ~ AdminPanel ~ heroData:", heroData);
  const [isLoading, setIsLoading] = useState(true); // Manage loading state
  const [uploading, setUploading] = useState(false);
  const [imageMap, setImageMap] = useState([]);
  const navigate = useNavigate(); // Navigation hook (optional)
  const [success, setSuccess] = useState(false); // Tracks the success state
  const [error, setError] = useState(""); // Tracks error message (if any)

  //console.log("🚀 ~ AdminPanel ~ imageMap:", imageMap)
  // const navigate = useNavigate();

  useEffect(() => {
    fetch("https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/data", {
      method: "GET",
      mode: "cors", // Explicitly enable CORS
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonData) => {
        // //console.log("🚀 ~ .then ~ jsonData:", jsonData);
        // setData(jsonData); // Set fetched data
        setAboutUsText(jsonData.about);
        setServicesData(jsonData.services);
        setCarouselImages(jsonData.carousel);
        setContactData({
          title: jsonData?.contact?.title,
          content: JSON.parse(jsonData?.contact?.content),
          image: jsonData?.contact?.image,
          add_ons: jsonData?.contact?.add_ons,
        });
        setMeatdataData(jsonData?.metadata);
        setHeroData(jsonData?.hero);
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Stop loading even on error
      });
  }, []);

  const updateApi = async (updatedData) => {
    try {
      fetch(
        "https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/data",
        {
          method: "POST",
          mode: "cors", // Explicitly enable CORS
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL",
          },
          body: JSON.stringify(updatedData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          //console.log("Data updated successfully:", result);
          return result;
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          return error.message;
        });
    } catch (error) {
      console.error("Error during database update:", error);
      return error.message;
    }
  };

  const updateDatabaseWithImage = async () => {
    try {
      //console.log("🚀 ~ dataToSend ~ imageMap:", imageMap)
      // Prepare the data to be sent to the backend
      imageMap.map(async (item) => {
        // Send the data to your backend (replace with your actual API endpoint)
        const response = await fetch(
          "https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/image",
          {
            method: "POST",
            mode: "cors", // Explicitly enable CORS
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL",
            },
            body: JSON.stringify({
              name: item.name, // image name
              image: item.image,
            }), // Send the data as JSON
          }
        );

        if (response.ok) {
          const result = await response.json();
          //console.log("Database updated successfully:", result);
          return result;
        } else {
          console.error("Failed to update database:", response.statusText);
          return response.statusText;
        }
      });
      //console.log("🚀 ~ dataToSend ~ dataToSend:", dataToSend);
      setImageMap([]);
    } catch (error) {
      console.error("Error during database update:", error);
      return error.message;
    }
  };

  const handleAboutUsChange = (e) => {
    setAboutUsText((prevData) => ({
      ...prevData, // Keep the previous state data intact
      content: e.target.value, // Update the content field
    }));
  };

  const handleServiceChange = (index, field, value) => {
    // Make a copy of the servicesData object
    const updatedServices = { ...servicesData };

    // Make a copy of the add_ons array to avoid direct mutation
    const updatedAddOns = [...updatedServices.add_ons];

    // Update the specific field for the service at the given index
    updatedAddOns[index] = {
      ...updatedAddOns[index], // Copy the existing data of the specific add_on
      [field]: value, // Update the specific field
    };

    // Set the updated servicesData state with the new add_ons array
    updatedServices.add_ons = updatedAddOns;
    setServicesData(updatedServices);
  };

  const handleCarouselChange = (index, field, value) => {
    // Make a shallow copy of the carouselImages object
    const updatedImages = { ...carouselImages };

    // Make a shallow copy of the add_ons array to avoid direct mutation
    const updatedAddOns = [...updatedImages.add_ons];

    // Update the specific field of the add_on at the given index
    updatedAddOns[index] = {
      ...updatedAddOns[index], // Spread the existing data at that index
      [field]: value, // Update the specific field with the new value
    };

    // Set the updated images state with the new add_ons array
    updatedImages.add_ons = updatedAddOns;
    setCarouselImages(updatedImages);
  };

  const handleMetadataChange = (index, field, value) => {
    // Make a shallow copy of the carouselImages object
    const updatedMetadata = { ...metadataData };

    // Make a shallow copy of the add_ons array to avoid direct mutation
    const updatedAddOns = [...updatedMetadata.add_ons];

    // Update the specific field of the add_on at the given index
    updatedAddOns[index] = {
      ...updatedAddOns[index], // Spread the existing data at that index
      [field]: value, // Update the specific field with the new value
    };

    // Set the updated images state with the new add_ons array
    updatedMetadata.add_ons = updatedAddOns;
    setMeatdataData(updatedMetadata);
  };

  const handleContactChange = (index, field, value) => {
    // Make a shallow copy of the contactData object
    const updatedContact = { ...contactData };

    // Update the specific field in the content object
    updatedContact.content = {
      ...updatedContact.content, // Spread the existing content to keep other fields intact
      [field]: value, // Update the specific field with the new value
    };

    // Set the updated state
    setContactData(updatedContact);
  };
  const handleServiceRemove = (indexToRemove) => {
    // Make a copy of the servicesData object
    const updatedServices = { ...servicesData };

    // Filter out the service at the specified index
    updatedServices.add_ons = updatedServices.add_ons.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the new array
    setServicesData(updatedServices);
  };

  const handleCarousalRemove = (indexToRemove) => {
    // Make a copy of the servicesData object
    const updatedCarousal = { ...carouselImages };

    // Filter out the service at the specified index
    updatedCarousal.add_ons = updatedCarousal.add_ons.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the new array
    setCarouselImages(updatedCarousal);
  };

  const handleMetaDataRemove = (indexToRemove) => {
    // Make a copy of the metadata object
    const updatedMetadata = { ...metadataData };

    // Filter out the service at the specified index
    updatedMetadata.add_ons = updatedMetadata.add_ons.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the new array
    setMeatdataData(updatedMetadata);
  };

  const handleImageChange = (index, field, e, type) => {
    const file = e.target.files[0];
    if (file) {
      // Compress image to below 3MB
      const options = {
        maxSizeMB: 3, // Maximum size in MB
        maxWidthOrHeight: 1024, // Optional: max dimension of the image
        useWebWorker: true, // Optional: Use web worker for performance
        fileType: "image/jpeg", // Convert to JPEG
      };

      // Compress the image
      imageCompression(file, options)
        .then((compressedFile) => {
          const fileName = compressedFile.name;
          //console.log("🚀 ~ .then ~ fileName:", fileName)
          const reader = new FileReader();

          reader.onloadend = () => {
            // Update imageMap with the new compressed image
            const base64String = reader.result;

            // //console.log("🚀 ~ .then ~ base64String:", base64String)
            const spltedBase64 = base64String.split(",")[1];
            setImageMap((prevImageMap) => [
              ...prevImageMap,
              { name: fileName, image: spltedBase64 },
            ]);

            // Update servicesData or carouselImages based on the type
            if (type === "service") {
              const updatedServices = { ...servicesData }; // Copy the entire object
              updatedServices.add_ons = [...updatedServices.add_ons]; // Spread the add_ons array
              updatedServices.add_ons[index][field] = fileName;
              setServicesData(updatedServices);
            } else if (type === "carousel") {
              const updatedImages = { ...carouselImages };
              updatedImages.add_ons = [...updatedImages.add_ons];
              updatedImages.add_ons[index][field] = fileName;
              setCarouselImages(updatedImages);
            }
          };
          reader.readAsDataURL(compressedFile); // Convert compressed image to Base64
        })
        .catch((error) => {
          console.error("Image compression failed:", error);
        });
    }
  };

  const handleServiceAdd = () => {
    setServicesData((prevCarousalData) => ({
      ...prevCarousalData, // Keep the existing properties of the carousalData state
      add_ons: [
        ...prevCarousalData.add_ons, // Retain existing add_ons
        { title: "", content: "", image: "" }, // Add a new empty addon object
      ],
    }));
  };

  const handleMetadatalAdd = () => {
    setMeatdataData((prevMetadataData) => ({
      ...prevMetadataData, // Keep the existing properties of the carousalData state
      add_ons: [
        ...prevMetadataData.add_ons, // Retain existing add_ons
        { title: "", content: "", image: "" }, // Add a new empty addon object
      ],
    }));
  };

  const handleCarouselAdd = () => {
    setCarouselImages((prevCarousalData) => ({
      ...prevCarousalData, // Keep the existing properties of the carousalData state
      add_ons: [
        ...prevCarousalData.add_ons, // Retain existing add_ons
        { title: "", content: "", image: "" }, // Add a new empty addon object
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      about: aboutUsText,
      carousel: carouselImages,
      services: servicesData,
      contact: {
        title: contactData.title,
        content: JSON.stringify(contactData.content),
        image: contactData.image,
        add_ons: contactData.add_ons,
      },
      hero: heroData,
      metadata: metadataData,
    };

    //console.log("🚀 ~ handleSubmit ~ updatedData:", updatedData);

    try {
      setUploading(true); // Show loader
      setSuccess(false);
      setError("");
      await updateApi(updatedData).then(async ()=>{
        if (imageMap.length) {
          await updateDatabaseWithImage().catch((error)=>{
            setError(error.message || "Something went wrong. Please try again.");
            console.error("Error updating data:", error);
          }).then(()=>{
            setSuccess(true); // Show success message
          }); // Wait for image database update if applicable
        }
        setSuccess(true); // Show success message

      }).catch((error)=>{
        setError(error.message || "Something went wrong. Please try again.");
        console.error("Error updating data:", error);
      }); // Wait for the API call to complete
      // alert("Data updated successfully!");
    } catch (error) {
      setError(error.message || "Something went wrong. Please try again.");
      console.error("Error updating data:", error);
      alert("Failed to update data. Please try again.");
    } finally {
      setUploading(false); // Hide loader
    }
  };

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();

    // Optionally redirect the user
    navigate("/"); // Change '/login' to your login route
  };

  return isLoading ? (
    <div>Loading ......</div>
  ) : (
    <div className="admin-panel">
      <div className="top_admin">
        <div>
          <h1>Admin Panel</h1>
        </div>
        <div>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              sx={{
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Logout
            </Button>
          </Box>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {/* About Us Section */}
        <div className="section about-us">
          <h2>About Us</h2>
          <textarea
            value={aboutUsText?.content}
            onChange={handleAboutUsChange}
            placeholder="Enter About Us section text"
            rows="4"
          />
        </div>
        {/* Services Section */}
        <div className="section services">
          <h2>Services</h2>
          {servicesData?.add_ons?.map((service, index) => (
            <div
              key={index}
              style={{ position: "relative", marginBottom: "20px" }}
            >
              {/* Cross Button to Remove the Service */}
              <button
                type="button"
                onClick={() => handleServiceRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "transparent",
                  border: "none",
                  color: "red",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                title="Remove Service"
              >
                ✖
              </button>
              <input
                type="text"
                value={service?.title}
                onChange={(e) =>
                  handleServiceChange(index, "title", e.target.value)
                }
                placeholder="Service Title"
              />
              <input
                type="text"
                value={service?.content}
                onChange={(e) =>
                  handleServiceChange(index, "description", e.target.value)
                }
                placeholder="Service Description"
              />
              <ImageFetcher
                image_name={service?.image}
                altText={`Slide ${index}`}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  marginBottom: 2,
                  objectFit: "cover",
                }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageChange(index, "image", e, "service")
                }
              />
            </div>
          ))}
          <button type="button" onClick={handleServiceAdd}>
            Add Service
          </button>
        </div>
        {/* Carousel Section */}
        <div className="section carousel">
          <h2>Carousel Images</h2>
          {carouselImages?.add_ons?.map((carousel, index) => (
            <div
              key={index}
              style={{ position: "relative", marginBottom: "20px" }}
            >
              {/* Cross Button to Remove the Service */}
              <button
                type="button"
                onClick={() => handleCarousalRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "transparent",
                  border: "none",
                  color: "red",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                title="Remove Service"
              >
                ✖
              </button>
              <input
                type="text"
                value={carousel?.title}
                onChange={(e) =>
                  handleCarouselChange(index, "title", e.target.value)
                }
                placeholder="Carousel Image Title"
              />
              <ImageFetcher
                image_name={carousel?.image}
                altText={`Slide ${index}`}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  marginBottom: 2,
                  objectFit: "cover",
                }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageChange(index, "image", e, "carousel")
                }
              />
            </div>
          ))}
          <button type="button" onClick={handleCarouselAdd}>
            Add Carousel Image
          </button>
        </div>
        {/* Contact Section */}
        <div className="section contact">
          <h2>Contact Details</h2>
          {contactData?.content &&
            Object.keys(contactData.content).length > 0 &&
            Object.entries(contactData.content).map(([key, value], index) => (
              <div key={index}>
                <input
                  type="text"
                  value={value}
                  onChange={(e) =>
                    handleContactChange(index, key, e.target.value)
                  }
                  placeholder={key} // This will use the key as the placeholder (e.g., "email", "hours", etc.)
                />
              </div>
            ))}
        </div>
        {/* Metadata Section */}
        <div className="section metadata">
          <h2>Metadata Details</h2>
          {metadataData?.add_ons?.map((metadata, index) => (
            <div
              key={index}
              style={{ position: "relative", marginBottom: "20px" }}
            >
              {/* Cross Button to Remove the Service */}
              <button
                type="button"
                onClick={() => handleMetaDataRemove(index)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "transparent",
                  border: "none",
                  color: "red",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                title="Remove metadata"
              >
                ✖
              </button>
              <input
                type="text"
                value={metadata.content}
                onChange={(e) =>
                  handleMetadataChange(index, "content", e.target.value)
                }
                placeholder="Metadata"
              />
            </div>
          ))}
          <button type="button" onClick={handleMetadatalAdd}>
            Add Metadata
          </button>
        </div>
        {/* Submit Button */} {/* Form fields go here */}
        {/* Form fields go here */}
        {/* <button type="submit" disabled={uploading}>
          {uploading ? "Updating..." : "Submit Changes"}
        </button> */}
        {/* Submit Button */}
        <Button
          type="submit"
          // variant="contained"
          // fullWidth
          sx={{
            backgroundColor: "#3E2723",
            color: "white",
            "&:hover": {
              backgroundColor: "#5D4037",
            },
          }}
          disabled={uploading} // Disable button while loading
        >
          {uploading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Submit Changes"
          )}
        </Button>
      </form>

      {/* Success Alert */}
      {success && (
        <Alert
          severity="success"
          sx={{ marginTop: 2 }}
          onClose={() => setSuccess(false)} // Dismiss the alert
        >
          Data updated successfully.....
        </Alert>
      )}

      {/* Error Alert */}
      {error && (
        <Alert
          severity="error"
          sx={{ marginTop: 2 }}
          onClose={() => setError("")} // Dismiss the alert
        >
          {error}
        </Alert>
      )}
      {/* </form> */}
    </div>
  );
};

export default AdminPanel;
