import React, { useState } from "react";
import { Box, Typography, Grid, Paper, TextField, Button, CircularProgress, Alert } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Footer from "./Footer";
import "./styles/contact.css";

const Contact = ({ data }) => {
  // Access `data` prop here
  const [loading, setLoading] = useState(false); // Tracks the API call state
  const [success, setSuccess] = useState(false); // Tracks the success state
  const [error, setError] = useState(""); // Tracks error message (if any)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formData);
    setLoading(true);
    setSuccess(false);
    setError("");

    fetch(
      "https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key":
            "nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        //console.log("Data updated successfully:", result);
        setSuccess(true); // Show success message
        setFormData({ name: "", email: "", message: "" ,phone: "",}); // Clear the form
      })
      .catch((error) => {
        setError(error.message || "Something went wrong. Please try again.");
        console.error("Error updating data:", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading indicator
      });
  };
  const content = JSON.parse(data?.content);
  return (
    <div className="contact_sec">
      <Box
        sx={{
          backgroundColor: "#F9F6E6",
          py: 6,
          px: 2,
          display: "flex",
          marginRight: "20px",
          flexDirection: "column",
          justifyContent: "flex-start",
          flexGrow: 1,
        }}
      >
        {/* Heading using Dancing Script and dark chocolaty color */}
        <Typography
          variant="h4"
          component="h2"
          sx={{
            mb: 4,
            fontWeight: "bold",
            fontFamily: '"Dancing Script", cursive',
            color: "#3E2723",
            textAlign: "center",
            position: "relative",
            "&::after": {
              content: '""',
              width: "80px",
              height: "4px",
              backgroundColor: "#3E2723",
              position: "absolute",
              bottom: "-8px",
              left: "50%",
              transform: "translateX(-50%)",
            },
          }}
        >
          Contact Us
        </Typography>

        <Grid
          container
          spacing={4}
          sx={{
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            flex: 1,
          }}
        >
          {/* Location Map */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <iframe
                title="Homelike Homestays Location"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                style={{ border: "none" }}
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=SAINANI%20MANSION,%201/266,%20Sukher%20Rd,%20near%20NATIONAL%20RADIATOR,%20Pratap%20Nagar,%20Udaipur,%20Rajasthan%20313001+(Homelike%20Homestays)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </Box>
          </Grid>

          {/* Contact Details and Form */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              paddingLeft: { xs: 0, sm: 2 },
              paddingRight: { xs: 0, sm: 2 },
            }}
          >
            <Paper
              elevation={6}
              sx={{
                padding: 3,
                borderRadius: 4,
                boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)",
                width: "100%",
                maxWidth: { xs: "100%", sm: "450px" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  textAlign: "center",
                  mb: 3,
                  fontFamily: '"Dancing Script", cursive',
                  color: "#3E2723",
                }}
              >
                Get in Touch
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MailIcon sx={{ color: "#3E2723", marginRight: 1 }} />
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: '"Mate SC", serif', color: "#3E2723" }}
                    >
                      {content.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PhoneIcon sx={{ color: "#3E2723", marginRight: 1 }} />
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: '"Mate SC", serif', color: "#3E2723" }}
                    >
                      {content.phone}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LocationOnIcon sx={{ color: "#3E2723", marginRight: 1 }} />
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: '"Mate SC", serif', color: "#3E2723" }}
                    >
                      {content.location}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AccessTimeIcon sx={{ color: "#3E2723", marginRight: 1 }} />
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: '"Mate SC", serif', color: "#3E2723" }}
                    >
                      {content.hours}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <form
                onSubmit={handleSubmit}
                style={{ marginTop: "20px", flexGrow: 1 }}
              >
                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#3E2723",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#5D4037",
                    },
                  }}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Contact Us"
                  )}
                </Button>
              </form>

              {/* Success Alert */}
              {success && (
                <Alert
                  severity="success"
                  sx={{ marginTop: 2 }}
                  onClose={() => setSuccess(false)} // Dismiss the alert
                >
                  Thank you for contacting us! We will get in touch with you
                  soon.
                </Alert>
              )}

              {/* Error Alert */}
              {error && (
                <Alert
                  severity="error"
                  sx={{ marginTop: 2 }}
                  onClose={() => setError("")} // Dismiss the alert
                >
                  {error}
                </Alert>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* Footer */}
      <Box sx={{ mt: 5 }}>
        <Footer />
      </Box>
    </div>
  );
};

export default Contact;
