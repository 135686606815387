import { useRef, useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import ImageFetcher from "./ImageFetcher";

// const services = [
//   {
//     title: "Homely Rooms",
//     description:
//       "Enjoy your stay in our comfortable rooms that come with all the amenities to ensure a homely feeling.",
//     image: "/images/services/img8.jpg",
//   },
//   {
//     title: "Prime Location",
//     description:
//       "Located in the heart of Udaipur, our hotel provides easy access to the city's main attractions and landmarks.",
//     image: "/images/services/img10.jpg",
//   },
//   {
//     title: "Pure Drinking Water, Anytime",
//     description: "Stay hydrated with safe and purified RO drinking water.",
//     image: "/images/services/ro-water.jpg",
//   },
//   {
//     title: "Room with Kitchen",
//     description:
//       "Opt for our rooms with kitchens for a more independent stay, where you can enjoy home-cooked meals.",
//     image: "/images/services/img9.avif",
//   },
//   {
//     title: "Customized Cooking and Ala carte",
//     description:
//       "Food options with customized cooking and Ala carte both available at reasonable price.",
//     image: "/images/services/img11.jpg",
//   },
//   {
//     title: "Stay Connected with Free WiFi",
//     description:
//       "Enjoy unlimited, high-speed WiFi access throughout your stay.",
//     image: "/images/services/free-wifi.png",
//   },
//   {
//     title: "Entertainment Anytime with Cable TV",
//     description:
//       "Watch your favorite shows and unwind with our free cable TV service.",
//     image: "/images/services/tv.jpg",
//   },
//   {
//     title: "Welcoming All Travelers and Groups",
//     description:
//       "Ideal for travelers, couples, friends, patients, and adventure enthusiasts.",
//     image: "/images/services/travellers.jpg",
//   },
//   {
//     title: "Perfect for Long-Term Stays",
//     description: "Affordable, comfortable, and ideal for extended rentals.",
//     image: "/images/services/long-terms.png",
//   },
//   {
//     title: "A Friendly Space for Bachelors",
//     description:
//       "Comfortable and convenient accommodations tailored for bachelors.",
//     image: "/images/services/bachelors.png",
//   },
// ];

const ServiceSection = ({ data }) => {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    const sectionElement = sectionRef.current;
    if (sectionElement) {
      observer.observe(sectionElement);
    }

    return () => {
      if (sectionElement) {
        observer.unobserve(sectionElement);
      }
    };
  }, []);

  return (
    <Box
      ref={sectionRef}
      sx={{
        backgroundColor: "#F9F6E6",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* Section Heading with Dark Chocolaty Color */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontFamily: '"Dancing Script", cursive',
          color: "#3E2723", // Dark chocolaty color for the heading
          position: "relative",
          marginBottom: 6,
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: "-10px",
            left: "50%",
            width: "100%",
            height: "4px",
            backgroundColor: "#6D4C41", // Underline in chocolaty shade
            transform: "translateX(-50%)",
          },
        }}
      >
        SERVICES
      </Typography>

      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: "1200px",
          alignItems: "stretch",
        }}
      >
        {data.add_ons.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              opacity: inView ? 1 : 0,
              transform: inView ? "translateY(0)" : "translateY(50px)",
              transition: `opacity 0.1s ease, transform 0.1s ease`, // Faster transition (0.1s)
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                backdropFilter: "blur(10px)",
                borderRadius: 2,
                boxShadow: 3,
                p: 3,
                textAlign: "center",
                height: "100%",
                mx: { xs: 4, md: 0 },
                // "&:hover": {
                //   transform: "translateY(-10px)",
                //   transition: "transform 0.1s ease",
                // },
              }}
            >
              <ImageFetcher
                image_name={service.image}
                altText={service.title}
                component="box"
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  marginBottom: 2,
                  objectFit: "cover",
                }}
              />

              {/* Service Title with Dark Chocolaty Color */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#3E2723", // Dark chocolaty color for service title
                  fontFamily: '"Mate SC", serif',
                }}
              >
                {service.title}
              </Typography>

              {/* Service Description with Dark Chocolaty Color */}
              <Typography
                variant="body2"
                sx={{
                  color: "#6D4C41", // Slightly lighter chocolaty shade for description
                  fontFamily: '"Mate SC", serif',
                  flexGrow: 1,
                }}
              >
                {service.content}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServiceSection;
