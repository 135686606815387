import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import theme from './theme/theme';
import AnimatedHeader from './components/AnimatedHeader';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Contact from './components/Contact';
import AdminPanel from './components/admin/AdminPanel';
import ImageCarousel from './components/ImageCarousel';
import LoginPage from './components/admin/LoginPage';
import MetadataHandle from './components/MetadataHandle';

const App = () => {
  const [data, setData] = useState({
    about: null,
    carousel: null,
    services: null,
    contact: null,
    hero: null,
    metadata: null,
  }); // Initialize with empty values
  //console.log("🚀 ~ App ~ data:", data)
  const [isLoading, setIsLoading] = useState(true); // Manage loading state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const carouselRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);
  const metadataRef = useRef(null);

  useEffect(() => {
    fetch('https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/data', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonData) => {
        setData(jsonData); // Set fetched data
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Stop loading even on error
      });
  }, []);

  const ProtectedRoute = ({ element, isAuthenticated }) => {
    const token = localStorage.getItem("admin_token")
    if(token && token !== undefined) {
      return element
    }else if(isAuthenticated){
      return element
    }
    return <Navigate to="/adminlogin" replace />;
  };

  const scrollToSection = (item) => {
    switch (item) {
      case 'Home':
        heroRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'About':
        aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Gallery':
        carouselRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Services':
        servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Contact':
        contactRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  // if (isLoading) return <div>Loading...</div>;

  return (
      <ThemeProvider theme={theme}>
        <Router>
          <Box
            sx={{
              backgroundColor: "#F9F6E6", // Set the background color for the entire app
              minHeight: "100vh", // Ensure it takes at least the full viewport height
              display: "flex",
              flexDirection: "column",
              maxWidth: "100vw", // Prevent the app from extending beyond the viewport width
              overflowX: "hidden", // Prevent horizontal scrolling
              gap: "6em",
            }}
          >
            <AnimatedHeader scrollToSection={scrollToSection} data={data?.contact} />
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <div ref={heroRef}>
                      <HeroSection />
                    </div>
                    <div ref={aboutRef}>
                      {data?.about && <AboutUs data={data?.about}  isLoading={isLoading} />}
                    </div>
                    <div ref={carouselRef}>
                      {data?.carousel && <ImageCarousel images={data?.carousel} isLoading={isLoading} />}
                    </div>
                    <div ref={metadataRef}>
                      {data?.metadata && <MetadataHandle data={data?.metadata} isLoading={isLoading} />}
                    </div>
                    <div ref={servicesRef}>
                      {data?.services && <Services data={data?.services} isLoading={isLoading} />}
                    </div>
                    <div ref={contactRef}>
                      {data?.contact && <Contact data={data?.contact} isLoading={isLoading} />}
                    </div>
                  </>
                }
              />
              <Route
                path="/adminlogin"
                element={<LoginPage onLogin={setIsAuthenticated} />}
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    element={
                      <Box sx={{ marginTop: { xs: '10px', sm: '10px' }, padding: '20px' }}>
                        <AdminPanel />
                      </Box>
                    }
                  />
                }
              />
            </Routes>
          </Box>
        </Router>
      </ThemeProvider>
  );
};

export default App;
