import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import "./animatedHeader.css";
import { useNavigate } from "react-router-dom";

const AnimatedHeaderWithMultipleFonts = ({ scrollToSection,data }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const content = data?.content ? JSON.parse(data?.content) : {}
  const navigate = useNavigate();


  const handleClick = (item) => {
    setActiveItem(item);
    scrollToSection(item);
  };

  const menuItems = ["Home", "About", "Services", "Contact"];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const addressParts = content?.location ? content.location.split(',') : [];
  return (
    <div className="header">
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#F9F6E6",
        boxShadow: scrolling ? "0 8px 16px rgba(0, 0, 0, 0.4)" : "none",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <div className="top-strip">
        <div className="container">
          <span className="contact-item">
            <strong>Phone:</strong> <a href="tel:+1234567890">{content?.phone}</a>
          </span>
          {/* <span className="contact-item"> */}
          <div className="address">
            <strong>Address:</strong>
            {addressParts.map((part, index) => (
              <span key={index}>{part.trim()}</span>
            ))}
          </div>
        {/* </span> */}
          <span className="contact-item">
            <strong>Email:</strong>{" "}
            <a href="mailto:info@example.com">{content?.email}</a>
          </span>
        </div>
      </div>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "space-between",
        }}
      >
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ position: "absolute", left: 7 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant={isMobile ? "h6" : "h4"}
          component="div"
          onClick={()=>{ navigate("/");}}
          sx={{
            fontWeight: "bold",
            fontFamily: '"Dancing Script", cursive', // Keep the Dancing Script font for the main heading
            letterSpacing: "0.1em",
            color: "#4E342E", // Chocolaty color for the heading
            textAlign: isMobile ? "center" : "left",
            width: isMobile ? "100%" : "auto",
            textShadow: "none",
            "&:hover": {
              textShadow: "none",
            },
          }}
        >
          HomeLike HomeStays
        </Typography>
        {!isMobile && (
          <Box sx={{ display: "flex" }}>
            {menuItems.map((item, index) => (
              <Typography
                key={index}
                variant="body1"
                component="div"
                onClick={() => handleClick(item)}
                className="kaushan-script-regular" // Apply Kaushan Script to the right-side menu items
                sx={{
                  mx: 3,
                  position: "relative",
                  cursor: "pointer",
                  color: activeItem === item ? "#D2691E" : "#6D4C41", // Chocolaty shades for the menu items
                  fontFamily: '"Mate SC", serif', // Use Mate SC here
                  fontWeight: "400",
                  letterSpacing: "0.01em",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "#D2691E", // Lighter chocolaty hover effect
                  },
                }}
              >
                {item}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -2,
                    left: 0,
                    height: "1px",
                    width: activeItem === item ? "100%" : 0,
                    backgroundColor: "#D2691E", // Chocolaty hover underline
                    transition: "width 0.2s ease-in-out",
                  }}
                />
              </Typography>
            ))}
          </Box>
        )}
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {menuItems.map((item, index) => (
                <ListItem button key={index} onClick={() => handleClick(item)}>
                  <ListItemText
                    primary={item}
                    sx={{ color: "#6D4C41", fontFamily: '"Mate SC", serif' }} // Chocolaty color for drawer items
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
    </div>
  );
};

export default AnimatedHeaderWithMultipleFonts;
