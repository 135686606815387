import { Box, Typography } from "@mui/material";

const HeroSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "75vh",
        backgroundColor: "#F9F6E6",
        backgroundImage: `
          linear-gradient(to right, rgba(195, 235, 247, 0.7), rgba(195, 235, 247, 0.0)),
          url('/images/img6.jpg')
        `,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: { xs: "16px", md: "48px" },
      }}
    >
      <Box sx={{ maxWidth: "600px" }}>
        {/* Main heading using Dancing Script font */}
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontWeight: "bold",
            mb: 2,
            color: "white",
            fontFamily: '"Dancing Script", cursive', // Apply Dancing Script font here
            textShadow:
              "2px 2px 5px rgba(0, 0, 0, 0.6), 4px 4px 10px rgba(0, 0, 0, 0.4)",
            display: "inline-block",
          }}
        >
          Homelike Homestays
        </Typography>

        {/* Subheading using Mate SC font */}
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: "600",
            color: "white",
            fontFamily: '"Mate SC", serif', // Apply Mate SC font here
            textShadow:
              "2px 2px 5px rgba(0, 0, 0, 0.6), 4px 4px 10px rgba(0, 0, 0, 0.4)",
            display: "inline-block",
          }}
        >
          a stay where you can get comfort level at Economical price in city of
          lakes Udaipur
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroSection;