// ImageCarousel.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageFetcher from "./ImageFetcher";
import Skeleton from "react-loading-skeleton";


export default function ImageCarousel({ images,isLoading }) {
  const settings = {
    infinite: true,
    speed: 200, // Faster transition duration in milliseconds
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1100, // Shorter time between slides in milliseconds
    fade: false,
    arrows: true,
    className: "carousel-slider",
  };

  const carouselContainerStyle = {
    width: window.innerWidth < 600 ? "80%" : "50%",
    margin: "0 auto",
    perspective: "1000px", // Add perspective for 3D effect
  };

  const slickSlideStyle = {
    transition: "transform 1s ease-in-out",
    transformStyle: "preserve-3d",
  };

  const imageStyle = {
    width: "100%",
    height: window.innerWidth < 600 ? 400 : 500, // Set fixed height for uniform dimensions
    objectFit: "fill", // Ensures the image fits inside the container without distortion
    borderRadius: "10px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)", // Add shadow for 3D effect
  };

  return (
    <div style={carouselContainerStyle}>
        {isLoading ? (
      <div>
        <Skeleton height={40} width={200} />
        <Skeleton count={3} />
      </div>
    ) : (
      <Slider {...settings}>
        {images.add_ons.length > 0 ? (
          images.add_ons.map((item, index) => (
            <div key={index} className="carousel-slide" style={slickSlideStyle}>
              {/* <img src={item.image} alt={`Slide ${index}`} style={imageStyle} /> */}
              <ImageFetcher
                image_name={item.image}
                altText={`Slide ${index}`}
                style={imageStyle}
              />
            </div>
          ))
        ) : (
          <div>No images available</div>
        )}
      </Slider>
      )}
      {/* Inline CSS for Arrow Customization */}
      <style>{`
        .slick-prev, .slick-next {
          color: rgba(0, 0, 0, 0.5); // Make the arrows slightly visible
          z-index: 1; // Ensure arrows appear above the slides
          font-size: 40px;
        }
        .slick-prev:hover, .slick-next:hover {
          color: rgba(0, 0, 0, 0.8); // Make the arrows more visible on hover
        }
        .slick-prev:before, .slick-next:before {
          color: rgba(0, 0, 0, 0.5); // Slightly visible arrow icons
        }
        .slick-prev:hover:before, .slick-next:hover:before {
          color: rgba(0, 0, 0, 0.8); // Brighter arrows on hover
        }
      `}</style>
    </div>
  );
}
