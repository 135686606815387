import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    // fontFamily: 'Plus Jakarta Sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#F9F6E6', // Customize primary color (purple)
    },
  },
});

export default theme;
