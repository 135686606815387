import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

const ImageFetcher = ({
  image_name,
  altText = "Image",
  style = {},
  sx = {},
  component = "img", // Default to <img>, switchable to "box"
  // fallbackImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8M2yJAAAAOXRFWHRTb2Z0d2FyZQBNYXRlcmlhbC1VSSBUaGVtZSBNYXNjb3JlIFZlcnNpb24yLjYuY2h1dGRtYWIuY29tAAAACXBIWXMAAB7CAAAewgFu0HU+AAEAAElEQVR4nOzdeXgVZ1jG8a+5mnYm2j3CZhCBhdqF1ZAgqHpMYM0lJiVxhE9fIpmT35tme1QfZiz0tF/q3QfxEO+r4lgFJpI1Is8y9zWqjCSdmsWimS2mk5dtCZkAqxe/sF1U1+1hnZnZrGfdzpdlnzxmpmjjOTllbdUwDJuPjj+vb6pQwpzIWofT13xwX8K15hFFKZlRu+2Plt9+vZOmd4eecXmNl1TZ9OGsxhzcZEvDiOWaDFiAq21xY7F7UEZq69Y0NyywFd/pFVvHMz2F1zDAmAcbNFQSCbpM7e1Um7y7h+TxaZ9VfaFJgLWyAqD72U30bXK0X1IjdBGYfqRbWqM4bBa5zAxV91bcl9XyNpmDAxphs8dQGiGJbSThRlTMmLCpzZJXdtUHZQEmPS5PSsV2mh1uCHFcqcs5oevDoV7TH3RmcqrbZ8qukhI2HjHRnmIs9GOT9G18ZBhtdPz/cT6df4g7Evh6Z8mXz1yQETVIMSSZimRUQpcv2COekyLD3ox2DhKATzI+SGT+6p5klPRMNbm31/9ZzGrSjr0qfXiwCKlGzX+fqbqddW21mfB/X6wgtDhYt1mjIbZmTpKOTyDA0zKsdPqC4KzIpxZDl5C7pyfJKXUNwL0zSZ3T1faHvPA7hft1wnCRJhtoyIxlZJlJUt9YWg4g0eShOqZrd6wQ9gt6NlDey14QxyEVlHJ19OHXW5MN85pybhhGsdOBt2xBhuGr91lMljzlswQCS26b+6DOw6yTPjygcWeg2N2nbsJ69vlt5lQ3gn/30g37n9DCqczV8wVwyqrzQThyy+1LlmGSsYPftkpgG8M8z6KKz9dZkgE36v4k9fJrdWTuSkcHKUpRQxq0JlPO5hfyDrbLgBYgXw64ER5RUbnMdbOsUV6dqqrr9gSyzJ7z1oQFh6P8mrXtGs/RggOB6+mTVldVeGpFJY1ch0O4Tk5l7zLO2IN9tOG0iYjqc9iW64Z7GJcvLe4HBe2M1oHJ5jdMzIcUttvNYn51ovV66+2bmNTTujMZGqnK35b5bmd4NnCZg4HrnZpk3B/01hZI6J7wMmOw5GT8oV1LCyOBPp9fL5ySMbsZauQro4zTkV3fFjphz5fPrF/1Tj85MvGqHwXYNCloNh84VqXxtBhMzs7+8f13HbBpNk8shAVfpoWjv1kX6O9lfOqElfmwAAw6Xl88U7mdtAMvc5hzpIu2lx2+GxB2aDOh0wRSLyqHwnmmPQbE51KkNK6NxOpniQUMpWmToUoA2jlzQ6HWZqsPQt1W2RxuV9gF3g8p9WVuYzQUzZTirw2kZtd8V0qlFYt1qHf4P98FgG5ybC2qdIC4fo/qijmLHF2UEy2qluRBCx+W1g5jFjyfMmfYcauUjV2Pvd9ZW75gdfMgswaCZ+Pj31VoVmyn1w5Lg+yAt5KIAcNYzWvfr5ydZwvDZZ7K3u91gdfjpaM2gfDAyn3hds6HqlI6bX5BfEzzFkgzAAAf4Gy5KmmbICwAAAABJRU5ErkJggg==", // Default Base64 fallback image
}) => {
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/image/${image_name}`,
          {
            method: "GET",
            // mode: "cors", // Explicitly enable CORS
            headers: {
              "Content-Type": "application/json",
              "x-api-key":"nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL",
            },
          }
        );
        const data = await response.json();
        // //console.log("🚀 ~ fetchImage ~ data:", data)
        setImageData(data.image); // Assume API returns a Base64 string in `data.image`
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };

    fetchImage();
  }, [image_name]);

  const imageSource = imageData ? imageData : "";


  if (component === "box") {
    return (
      <Box
        component="img"
        src={imageSource}
        alt={altText}
        sx={sx}
      />
    );
  }

  return (
    <img
      src={imageSource}
      alt={altText}
      style={style}
    />
  );
};


export default ImageFetcher;
