// AboutUs.js
import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";

const MetadataHandle = ({ data }) => {
  //console.log("🚀 ~ MetadataHandle ~ data:", data)
  const textBoxRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        });
      },
      { threshold: 0.3 }
    );

    const currentTextBox = textBoxRef.current;

    if (currentTextBox) {
      observer.observe(currentTextBox);
    }

    return () => {
      if (currentTextBox) {
        observer.unobserve(currentTextBox);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#F9F6E6", // Background color
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: "1200px",
          alignItems: { xs: "center", md: "stretch" }, // Center align items on mobile
          justifyContent: "center", // Center content horizontally on mobile
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Grid item xs={12} md={7} display="flex" justifyContent="center">
          <Box
            ref={textBoxRef} // Attach ref to the box
            sx={{
              p: 2,
              borderRadius: 2,
              background: "linear-gradient(135deg, #ffffff 0%, #e6f2ff 100%)", // Gradient background for the card
              boxShadow: 3,
              overflow: "hidden", // Ensure text does not overflow the box
              transform: isInView ? "translateY(0)" : "translateY(20px)", // Animate from below
              transition: "transform 2s ease", // Transition effect
              willChange: "transform", // Optimize for smooth animation
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center content vertically
              textAlign: "center", // Center text
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontWeight: "bold",
                mb: 2,
                fontFamily: '"Dancing Script", cursive',
                color: "#4E342E",
              }}
            >
              Features
            </Typography>
  
            {/* <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: '"Mate SC", serif',
                color: "#6D4C41",
              }}
            >
              {data?.content || "Default content here"}
            </Typography> */}
  
            {data?.add_ons?.map((item,i ) => (
              <Typography
                key={i}
                variant="body1"
                component="p"
                sx={{
                  fontFamily: '"Mate SC", serif',
                  color: "#6D4C41",
                  fontSize:"20px"
                }}
              >
                {item?.content}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetadataHandle;
